.navbar {
  position: sticky;
  top: 0;
  background-color: #333;
  z-index: 1000;
  padding: 10px 0;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.navbar ul li {
  padding: 0 20px;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  cursor: pointer; /* Changes cursor to pointer */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.navbar ul li a:hover, .navbar ul li a:focus {
  color: #ddd; /* Lightens the link color on hover/focus */
}

.navbar a {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Standard syntax */
}

