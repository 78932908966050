/* Services.css */
.services {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  overflow-x: auto;
}

.service-item {
  flex: 0 1 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: flex-basis 0.5s ease;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.service-item.expanded {
  flex-basis: 70%;
}

.service-item h2, .service-item p {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
