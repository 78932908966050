/* Home.css */
.quote {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #333;
  padding: 20px;
  background-color: #f0f0f0; /* Soft background color */
}

.quote-image {
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-image img {
  width: 100%;
  max-width: 400px; /* Adjust based on your preference */
  height: auto;
}

.quote-content {
  flex: 0 0 60%;
  padding-left: 20px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .quote {
      flex-direction: column;
      text-align: center;
  }

  .quote-content {
      padding-left: 0;
      padding-top: 20px;
  }
}
