.quote-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.required {
  color: red;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 500px;
}

input, textarea, button {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.quote-form-container textarea {
  resize: none;
}


button {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
