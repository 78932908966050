.contact{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #333;
  padding: 20px;
  background-color: #f0f0f0; /* Soft background color */
}


.contact-method {
  flex: 1;
  margin: 0 20px; /* Adds spacing between each method */
  text-align: center;
}

.contact-method i {
  font-size: 2rem; /* Icon size, adjust as needed */
  margin-bottom: 10px;
  display: block;
}

.contact-method p, .contact-method a {
  color: #333;
  text-decoration: none;
  font-size: 1.2rem; /* Text size, adjust as needed */
}

/* If you find the content too spread out on very wide screens, consider setting a max-width */
.contact-info {
  display: flex;
  max-width: 1200px; /* Adjust as needed */
  width: 100%;
}

@media (max-width: 768px) {
  .contact-info {
      flex-direction: column;
      align-items: center;
  }

  .contact-method {
      margin: 10px 0; /* Adjust spacing for smaller screens */
  }
}
